import React from 'react'

const Dashboard = () => {
  return (
      <div style={{
        marginTop:"30px"
    }}>Dashboard</div>
  )
}

export default Dashboard